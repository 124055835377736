export default {
  colors: {
    primary: '#1A953F',
    background: '#FFFFFF',
    shape: `#BCF2E7`,
    title: `#1A953F`,
    text: `#6C6C80`,
    components: {
      blockquote: {
        background: `#33291D`,
        text: `#E1E1E6`,
      },
    },
  },
};
